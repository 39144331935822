import React from "react"
import Layout from "components/layout"
import usePageData from 'hooks/queryTermAndConditions';
import ViewTermsAndConditions from 'components/ViewTermsAndConditions';

const TermsConditionsPage = props => {
  const { pageContext } = props;
  const pageData = usePageData();

  return (
    <Layout pageContext={pageContext}>
      <ViewTermsAndConditions pageData={pageData} />
    </Layout>
  )
}

export default TermsConditionsPage
